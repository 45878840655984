import zenscroll from 'zenscroll';

export default () => {
    return (elementId: string) => {
        const el = document.getElementById(elementId);
        if (null === el) {
            throw `The given element id ${elementId} does not exists.`;
        }
        zenscroll.to(el);
    };
};
