// Import current Livewire and AlpineJs
// @ts-expect-error Livewire do not use typescript
import { Livewire as LivewireImport, Alpine as AlpineImport } from '@/livewire';

// Enable power of typescript from here
const Livewire = LivewireImport as TLivewire;
const Alpine = AlpineImport as TAlpine;

/**
 * Import plugins
 */
import focus from '@alpinejs/focus';
/**
 * Import components
 */
import selectMultiple from '@/app/components/alpinejs/SelectMultiple';
import selectUnique from '@/app/components/alpinejs/SelectUnique';
import estimateExporter from '@/app/components/alpinejs/EstimateExporter';
import estimateLineReader from '@/app/components/alpinejs/EstimateLineReader';
import estimateLineDocumentExporter from '@/app/components/alpinejs/EstimateLineDocumentExporter';
import secondCounter from '@/app/components/alpinejs/SecondCounter';
import submitPurchaseOrder from '@/app/components/alpinejs/SubmitPurchaseOrder';
import teamPermissionToggle from '@/app/components/alpinejs/TeamPermissionToggle';
import statisticsIndex from '@/app/components/alpinejs/StatisticsIndex';
/**
 * Import Magics
 */
import scrollToMagic from '@/app/magics/alpinejs/scrollTo';

/**
 * Set plugins
 */
Alpine.plugin(focus);

/**
 * Set components
 */

Alpine.data('selectMultiple', selectMultiple);
Alpine.data('selectUnique', selectUnique);
Alpine.data('estimateExporter', estimateExporter);
Alpine.data('estimateLineReader', estimateLineReader);
Alpine.data('estimateLineDocumentExporter', estimateLineDocumentExporter);
Alpine.data('secondCounter', secondCounter);
Alpine.data('submitPurchaseOrder', submitPurchaseOrder);
Alpine.data('teamPermissionToggle', teamPermissionToggle);
Alpine.data('statistics', statisticsIndex);

/**
 * Set Magics
 */
Alpine.magic('scrollTo', scrollToMagic);

// Final step - Do not publish Alpine to window bellow
Livewire.start();
