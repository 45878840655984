export default {
    /**
     * Checks if a value is a valid float
     *
     * @param value Is the value a valid float ?
     */
    isValidFloat(value: number): boolean {
        return !isNaN(value);
    },

    /**
     * Ensure a value use the float "." notation
     * @param value
     * @returns String
     */
    forceEnglishFloatNotation(value: string): string {
        return value.replace(",", ".");
    },
};
