import { createAlpineComponent } from '@/app/services/utils';
import { Card, Donut, DonutChart, AlpineData, LivewireData, LivewireMethods, Table } from "@/interfaces/Components/StatisticsIndex";
import { Chart } from "chart.js";
import is from "@/app/services/is";
import { InvalidArgumentException, MissingHTMLElement } from "@/app/exceptions";


const defaultChart: DonutChart = {
    chartLabel: '',
    itemLabels: [],
    backgroundColors: [],
    itemData: [],
};

export default () => createAlpineComponent<AlpineData, LivewireData, LivewireMethods>({
    cards: [],
    donuts: {
        device: {
            chart: defaultChart,
            items: [],
        },
        browser: {
            chart: defaultChart,
            items: [],
        },
    },
    table: {
        header: [],
        body: [],
    },

    init() {
        this.$wire.$call('getCardsData').then((data: unknown) => {
            if (!is.array<Card>(data)) {
                throw new InvalidArgumentException('The given content is not an array');
            }
            this.cards = data;
        });

        this.$wire.$call('getDeviceDonutData').then((donut: unknown) => {
            // Check the type of data
            if (!is.object<Donut>(donut)) {
                throw new InvalidArgumentException("The donut is not an object");
            }
            this.donuts.device = donut;
            // Retrieve html element
            const el = this.getCanvasFromId('deviceActivity');

            new Chart(el, {
                type: 'doughnut',
                data: {
                    labels: this.donuts.device.chart.itemLabels,
                    datasets: [{
                        label: this.donuts.device.chart.chartLabel,
                        backgroundColor: this.donuts.device.chart
                            .backgroundColors,
                        data: this.donuts.device.chart.itemData,
                        borderWidth: 1,
                        // @ts-expect-error Radius prperty exists in the web documentation
                        radius: '80%',
                    }],
                },
            });
        });

        this.$wire.$call('getBrowserDonutData').then((donut: unknown) => {
            // Check the type of data
            if (!is.object<Donut>(donut)) {
                throw new InvalidArgumentException("The donut is not an object");
            }
            // Retrieve html element
            const el = this.getCanvasFromId('browserActivity');

            this.donuts.browser = donut;

            new Chart(el, {
                type: 'doughnut',
                data: {
                    labels: donut.chart.itemLabels,
                    datasets: [{
                        label: donut.chart.chartLabel,
                        backgroundColor: donut.chart
                            .backgroundColors,
                        data: donut.chart.itemData,
                        borderWidth: 1,
                        // @ts-expect-error Radius prperty exists in the web documentation
                        radius: '80%',
                    }],
                },
            });
        });

        this.$wire.$call('getStatTableData').then((table: unknown) => {
            // Check the type of data
            if (!is.object<Table>(table)) {
                throw new InvalidArgumentException("The donut is not an object");
            }

            this.table = table;
        });
    },

    getCanvasFromId(id: string): HTMLCanvasElement {
        const el = document.getElementById(id) as HTMLCanvasElement | null;
        if (null === el) {
            throw new MissingHTMLElement(`The html element with id "${id}" is unreachable`);
        }
        return el;
    },
});
