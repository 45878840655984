import { ExportStatus } from '@/app/enums';
import { AlpineData, LivewireData, LivewireMethods } from '@/interfaces/Components/EstimateExporter';
import LivewireResponse, { LivewireResponseInterface } from '@/app/services/LivewireResponse';
import config from '@/js/config.json';
import is from '@/app/services/is';
import { InvalidArgumentException } from '@/app/exceptions';
import { createAlpineComponent } from '@/app/services/utils';
interface RanExportBody {
    filename?: string
}

interface PendingExportBody {
    treatment_began: boolean,
    sheet_current_index: number,
    sheet_total_quantity: number,
    sheet_current_name: string,
    percentage: number,
    should_ask_later: boolean,
}

const exportSessionKeyInStorage: string = config.components.estimateExporter.localStorageKey;

export default (
    documentText: string = 'Document',
    messageStepOne: string = "Step 1",
    messageStepTwo: string = 'Step 2',
    messageStepThree: string = 'Step 3',
) => createAlpineComponent<AlpineData, LivewireData, LivewireMethods>({
    exportStatus: ExportStatus.WaitingUserAction,
    exportMessage: messageStepOne,
    exportProgressionMessage: '',
    exportFilename: '',
    isRequestingExport: false,
    percentage: 0,

    /**
     * Initialize the component
     */
    async init() {

        // if (this.didAnExportAlreadyStarted()) {
        //     // Try to retrieve the filename of a previous runned export
        //     const exportFilename = this.getStoredExportData()?.filename;
        //     if (undefined === exportFilename || null === exportFilename || '' === exportFilename) {
        //         this.cleanExportStorage();
        //         throw 'The export filename was not found in the local storage. Perhaps the storage is corrupted, it will be reset.';
        //     } else {
        //         // Change the status to show the waiting display
        //         this.exportStatus = ExportStatus.GeneratingFile;
        //         this.exportMessage = messageStepTwo;

        //         // Set the filename property of the livewire component (this affect the openned link when the file is fully generated)
        //         await this.$wire.set('exportFilename', exportFilename);
        //         this.exportFilename = exportFilename;
        //         this.checkExportStatus();
        //     }
        // }
    },

    /**
     * Trigger export to excel with custom filters registered in the current livewire component.
     * If any excel sheet check fails, the livewire component/view will manage that.
     */
    exportToExcel() {
        this.isRequestingExport = true;
        this.exportMessage = messageStepOne;
        this.exportProgressionMessage = '';

        this.$wire.$call('exportContentToExcel')
            .then((livewireResponse) => {
                if (!is.object(livewireResponse)) {
                    throw new InvalidArgumentException('The ajax response is not and object');
                }

                this.isRequestingExport = false;
                const res = new LivewireResponse<RanExportBody>(livewireResponse as LivewireResponseInterface<RanExportBody>);

                if (res.isOk()) {
                    // Register the export filename both in the component and in the local storage
                    const filename = res.getBody().filename;
                    if (undefined === filename) {
                        throw new Error('The Livewire response body should have a property "filename"');
                    }

                    this.exportFilename = filename;

                    this.storeExportData({
                        filename: this.exportFilename,
                    });

                    // Change the status to show the waiting display
                    this.exportStatus = ExportStatus.Done;
                    // Change the message after a few time to let the user read messages
                    setTimeout(() => { this.exportMessage = messageStepTwo; }, 500);
                    // Check the export state only one second after
                    // setTimeout(() => { this.checkExportStatus(); }, 1000);
                } else {
                    // For debugging
                    // this.exportStatus = ExportStatus.GotAnError;
                }

            });
    },

    /**
     * Check any 1 second the export is finished.
     */
    async checkExportStatus() {
        const livewireResponse = await this.$wire.$call('checkExportStatus', this.exportFilename) as LivewireResponseInterface<PendingExportBody>;
        const res = new LivewireResponse<PendingExportBody>(livewireResponse);

        if (res.isOk()) {
            const body = res.getBody();

            // If the export treatment has begun, we update the user message to show treatment advancement
            if (body.treatment_began) {
                this.exportMessage = messageStepThree;
                this.exportProgressionMessage = `${documentText} ${body.sheet_current_index}/${body.sheet_total_quantity} : ${body.sheet_current_name} ( ${body.percentage} %)`;
                this.percentage = body.percentage;
            }

            if (body.should_ask_later) {
                // Rerun the export checking method in a few seconds
                setTimeout(() => {
                    this.checkExportStatus();
                }, 2000);
            } else {
                // Set the export status to DONE then download the file automatically
                this.exportStatus = ExportStatus.Done;
                const el = document.getElementById('excel-export-url');
                if (null === el) {
                    throw new Error('The id "excel-export-url" is not defined');
                }
                el.click();
                // Remove the export data from the local storage
                this.cleanExportStorage();
            }
        } else if (400 === res.getStatus()) {
            this.exportStatus = ExportStatus.GotAnError;
            this.cleanExportStorage();
        }
    },

    /**
     * Checks the export has already started previously
     */
    didAnExportAlreadyStarted(): boolean {
        return null !== this.storage().getItem(exportSessionKeyInStorage);
    },

    /**
     * Retrieve data from the previsouly started export
     */
    getStoredExportData(): unknown {
        const storedContent = this.storage().getItem(exportSessionKeyInStorage);
        if (null === storedContent) {
            throw new Error(`You cannot access the stored item with key "${exportSessionKeyInStorage}" before it is defined`);
        }
        return JSON.parse(storedContent);
    },

    /**
     * Store data about an export
     * @param data Export data
     */
    storeExportData(data: unknown) {
        this.storage().setItem(exportSessionKeyInStorage, JSON.stringify(data));
    },

    /**
     * Clean any data about export that previously started
     */
    cleanExportStorage() {
        this.storage().removeItem(exportSessionKeyInStorage);
    },

    /**
     * Tells the frontend to stop following the current export
     */
    stopFollowingExport() {
        this.exportStatus = ExportStatus.WaitingUserAction;
        this.exportFilename = '';
        this.exportMessage = messageStepOne;
        this.cleanExportStorage();
    },

    /**
     * Get access to the storage (in this case, localStorage)
     */
    storage(): Storage {
        return window.localStorage;
    },

    /**
     * Check the export status to be done.
     * @returns
     */
    isDone(): boolean {
        return this.exportStatus === ExportStatus.Done;
    },

    /**
     * Check the export status to have an error.
     * @returns
     */
    gotAnError(): boolean {
        return this.exportStatus === ExportStatus.GotAnError;
    },
});
