import { BannerStatus } from '@/app/enums';
import * as Livewire from '@/interfaces/AlpineWired';
import { AlpineData, LivewireData, LivewireMethods } from '@/interfaces/Components/EstimateLineDocumentExporter';
import { ErrorBag } from '@/interfaces/DTOs/LineEdition';
import { createAlpineComponent } from '@/app/services/utils';

type Self = Livewire.AlpineComponentItself<AlpineData, LivewireData, LivewireMethods>;

interface UploadResponse {
    error: boolean,
    errorMessage: string,
    errorBag: ErrorBag,
    successMessage: string,
    documentName: string,
    documentId: string,
}

export default (
    lineId: number,
    allowedMimes: Array<string> = [],
    updloadErrorMessage: string | null = null,
    invalidMimeErrorMessage: string | null = null,
) => createAlpineComponent<AlpineData, LivewireData, LivewireMethods>({
    isUploading: false,
    hasSuccessfullyUploaded: false,
    hasError: false,
    progress: 0,
    file: null,
    lineIdToAttachDocument: lineId,
    messages: {
        base: updloadErrorMessage ?? 'The file upload had an error',
        mime: invalidMimeErrorMessage ?? 'Invalid file (mime) type',
    },
    allowedMimes,

    init() {
        this.$watch('file', (file: File | null) => {
            if (null === file) {
                return;
            }

            // Check mime content front side before sending file to livewire
            const extensionIndex = file.name.lastIndexOf('.');
            const mime = file.name.substring(extensionIndex + 1);

            if (allowedMimes.includes(mime)) {
                this.submitFileToLivewire();
            } else {
                this.$dispatch('banner-message', {
                    style: BannerStatus.Error,
                    message: this.messages.mime,
                });
            }
        });
    },

    /**
     * Submit a file to a livewire component
     */
    submitFileToLivewire(): void {
        if (null === this.file) {
            throw new Error('Cannot submit no file');
        }

        this.hasSuccessfullyUploaded = false;
        this.hasError = false;
        this.isUploading = true;
        this.progress = 0;

        this.$wire.upload(
            'file',
            this.file,
            () => this.finishCallback(this),
            () => this.errorCallback(this),
            (event: CustomEvent) => this.progressCallback(event, this),
        );
    },

    /**
     * What happen after our upload is finished
     *
     * @param uploadedFilename The filename of an uploaded file
     */
    finishCallback(self: Self): void {

        self.isUploading = false;
        self.hasSuccessfullyUploaded = true;
        // Tells the estimate line reader component to be in update state
        self.$dispatch('is-updating');

        // Tell livewire to register the file
        self.$wire.$call('addFileToLine', self.lineIdToAttachDocument)
            .then((data: unknown) => {
                const response = data as UploadResponse;

                if (response.error) {
                    self.hasError = true;
                    self.$dispatch('banner-message', {
                        style: BannerStatus.Error,
                        message: response.errorMessage,
                    });
                    return;
                }

                const errorBag = response.errorBag;

                self.$dispatch('banner-message', {
                    style: BannerStatus.Success,
                    message: response.successMessage,
                });
                self.$dispatch('file-attached', {
                    lineId: self.lineIdToAttachDocument,
                    filename: response.documentName,
                    documentId: response.documentId,
                    errorBag: errorBag,
                });
                // Tells the estimate line reader component to be in update state
                self.$dispatch('is-not-updating-anymore');

                self.hasSuccessfullyUploaded = false;

            })
            .catch(() => {
                self.hasError = true;
                self.$dispatch('banner-message', {
                    style: BannerStatus.Error,
                    message: this.messages.base,
                });
            });
    },

    /**
     * What happen when file submitting encounter any error
     */
    errorCallback(self: Self): void {
        self.isUploading = false;
        self.hasError = true;
    },

    /**
     * What happen when progressing while uploading
     */
    progressCallback(event: CustomEvent, self: Self): void {
        self.progress = event.detail.progress;
    },
});
