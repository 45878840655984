import { PurchaseOrderDoesntExists } from "@/app/exceptions";
import * as Livewire from "@/interfaces/AlpineWired";
import { createAlpineComponent } from '@/app/services/utils';
import { CheckResponse, AlpineData, LivewireData, LivewireMethods } from "@/interfaces/Components/SubmitPurchaseOrder";
import { ResultResponse } from "@/interfaces/Responses/SubmitPurchaseOrder";
import { handleError } from "@/app/services/utils";

type WireInstance = Livewire.WireMagic<LivewireData, LivewireMethods>;

export default () => createAlpineComponent<AlpineData, LivewireData, LivewireMethods>({
    displaySubmittingBlock: false,
    isPurchaseOrderCreated: false,
    isPurchaseOrderAlreadyCreated: false,
    isPurchaseOrderUpdated: false,
    isPurchaseOrderAlreadyUpdated: false,
    isPurchaseOrderExists: false,
    pendingSubmitPurchaseOrder: false,
    isUpdating: false,
    errorMessage: "",
    hasAllNeeded: false,
    count: 0,

    /**
     * The user would like to create/update a purchase order
     *
     * @param purchaseOrderId An optional existing purchase order id
     */
    wouldLikeToSubmitPo(purchaseOrderId: number | null = null): undefined {
        if (this.isUpdating) {

            return;
        }

        // Reset the error message
        this.errorMessage = '';
        this.isPurchaseOrderAlreadyCreated = false;
        this.isPurchaseOrderAlreadyUpdated = false;

        // A click display/Hide the submitting part
        this.displaySubmittingBlock = true;

        // We only perform request when the block is displayed
        if (!this.displaySubmittingBlock) {

            return;
        }

        // The purchase order should be to create...
        if (null === purchaseOrderId) {
            // Prevent multiple PO creations
            if (this.isPurchaseOrderCreated) {
                this.isPurchaseOrderAlreadyCreated = true;

                return;
            }

            // Set display as pending
            this.isUpdating = true;

            this.$wire.$call('canCreatePurchaseOrder')
                .then((data) => this.checkCanCreatePurchaseOrder(data as CheckResponse, this.$wire))
                .catch((e) => this.throwsError(e, this.$wire));

            return;
        }
        // Or to update.

        // Prevent multiple PO creations
        if (this.isPurchaseOrderUpdated) {
            this.isPurchaseOrderAlreadyUpdated = true;

            return;
        }

        // Set display as pending
        this.isUpdating = true;

        this.$wire.$call('canUpdatePurchaseOrder')
            .then((data) => this.checkCanUpdatePurchaseOrder(data as CheckResponse, this.$wire))
            .catch((e) => this.throwsError(e, this.$wire));
    },

    /**
     * Check if you can create a purchase order.
     *
     * @param response
     * @param wire The $wire instance
     */
    checkCanCreatePurchaseOrder(response: CheckResponse, wire: WireInstance): undefined {
        // Update display to show everything is okay
        this.hasAllNeeded = response.hasAllNeeded ? true : false;
        this.isPurchaseOrderExists = response.isPurchaseOrderExists ? true : false;

        // Update display now
        if (!this.hasAllNeeded) {
            this.isUpdating = false;

            return;
        }

        // Prevent multiple PO creation (from response content)
        if (this.isPurchaseOrderExists) {
            this.isPurchaseOrderAlreadyCreated = true;

            return;
        }

        // We can perform the creation only if we have everything required
        this.callCreatePurchaseOrder(wire);
    },

    /**
     * Ask backend to create the purchase order.
     *
     * @param wire The $wire instance
     */
    callCreatePurchaseOrder(wire: WireInstance): void {
        this.isUpdating = true;


        wire.$call('createPurchaseOrder')
            .then((data: unknown) => {
                const response: ResultResponse = data as ResultResponse;

                this.isUpdating = false;

                // Can either reset or set a new message
                this.errorMessage = response.errorMessage;

                if (response.done) {
                    this.isPurchaseOrderCreated = true;
                    wire.$dispatch('refreshPurchaseOrder');
                }
            })
            .catch((e) => this.throwsError(e, wire));
    },

    /**
     * Check if you can create a purchase order.
     *
     * @param response
     * @param wire The $wire instance
     */
    checkCanUpdatePurchaseOrder(response: CheckResponse, wire: WireInstance): undefined {
        // Update display to show everything is okay
        this.hasAllNeeded = response.hasAllNeeded ? true : false;
        this.isPurchaseOrderExists = response.isPurchaseOrderExists ? true : false;

        // Update display now
        if (!this.hasAllNeeded) {
            this.isUpdating = false;

            return;
        }

        // Prevent multiple PO update (from response content)
        if (!this.isPurchaseOrderExists) {
            this.throwsError(
                new PurchaseOrderDoesntExists("The purchase order was supposed to be updated, but no purchase order was actually referenced."),
                wire,
            );
        }

        // We can perform the update only if we have everything required
        this.callUpdatePurchaseOrder(wire);
    },

    /**
     * Ask backend to update the purchase order.
     * @param wire The $wire instance
     */
    callUpdatePurchaseOrder(wire: WireInstance): void {

        wire.$call('updatePurchaseOrder')
            .then((data) => {
                const response: ResultResponse = data as ResultResponse;

                this.isUpdating = false;

                // Can either reset or set a new message
                this.errorMessage = response.errorMessage;

                if (response.done) {
                    this.isPurchaseOrderUpdated = true;
                }
            })
            .catch((e) => this.throwsError(e, wire));
    },

    /**
     * Display an error to the user
     */
    throwsError(error: Error, wire: WireInstance): never {
        if (undefined === error || null === error || !(error instanceof Error)) {
            error = new Error("Unknown error");
        }
        wire.$call('addLogAboutPurchaseOrder', error.name, error.message);

        handleError(error);
    },
});
