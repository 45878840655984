import { AlpineData } from "@/interfaces/Components/SelectMultiple";
import is from "@/app/services/is";
import { createAlpineComponent } from '@/app/services/utils';

export default (
    livewireData: string,
    list: Array<string> = [],
    defaults: Array<string> = [],
    disabled: boolean = false,
) => createAlpineComponent<AlpineData, Record<string, unknown>, Record<string, unknown>>({
    open: false,
    values: [],
    defaultValues: [],
    list: list,
    livewireData: livewireData,
    disabled: disabled,

    /**
     * Initialize the component
     */
    init() {
        if (!is.array(defaults)) {
            throw new Error('The default values should be an array');
        }

        if (0 !== defaults.length) {
            this.values = structuredClone(defaults);
            this.defaultValues = structuredClone(defaults);
        }
        this.$watch('values', (values: unknown) => {
            if (is.array(values) && values.every(val => is.string(val))) {
                this.publish(values);
            }
        });
    },

    /**
     * Detect if should add or remove a value
     * @param value The value to add/remove
     */
    addOrRemoveValue(value: string) {
        if (!this.values.includes(value)) {
            this.addValue(value);
        } else {
            this.removeValue(value);
        }
    },

    /**
     * Add a value in the select bag
     * @param value The value to add in our set of values
     */
    addValue(value: string) {
        if (!this.values.includes(value)) {
            this.values.push(value);
        }
    },

    /**
     * Remove a value from the select bag
     * @param value The value to remove from the select bag
     */
    removeValue(value: string) {
        if (this.values.includes(value)) {
            const index = this.values.indexOf(value);
            this.values.splice(index, 1);
        }
    },

    /**
     * Checks if a value is already selected
     * @param value The value to check
     */
    isSelected(value: string) {
        return this.values.includes(value);
    },

    /**
     * Update our value into livewire (the backend)
     * @param values Values to publish to Livewire
     */
    publish(values: Array<string>) {
        if (!this.disabled) {
            this.$wire.$set(this.livewireData, values);
        }
    },

    /**
     * Reset the values bag.
     */
    reset() {
        this.values = structuredClone(this.defaultValues);
    },
});
