import { Permission } from "@/app/enums";
import { AlpineData, LivewireData, LivewireMethods, UserDto } from "@/interfaces/Components/TeamPermissionToggle";
import is from "@/app/services/is";
import { InvalidArgumentException, UserNotFoundException } from "@/app/exceptions";
import { handleError } from "@/app/services/utils";
import { createAlpineComponent } from '@/app/services/utils';


export default () => createAlpineComponent<AlpineData, LivewireData, LivewireMethods>({
    users: [],
    booted: false,
    loading: false,
    backendCallCount: 0,

    /**
     * Initialize data.
     */
    init() {
        this.$wire
            .$call('getPermissionPanelData')
            .then(users => {
                if (!is.array<UserDto>(users)) {
                    throw new InvalidArgumentException('The ajax response need to give an array');
                }
                this.users = users;
                this.booted = true;
            })
            .catch(e => handleError(e));
    },

    /**
     * Update the team permissions.
     * @param userId The user to update the team permission
     * @param permissionName The permission name to update
     */
    updateTeamPermission(userId: number, permissionName: Permission): void {
        if (!Object.values(Permission).includes(permissionName)) {
            throw `The permission ${permissionName} is not supported`;
        }

        // Find the user in the array
        const user = this.users.find((dto: UserDto) => dto.id === userId);
        if (undefined === user) {
            throw new UserNotFoundException(`The user with id ${userId} was not found in the collection`);
        }
        // You cannot read without write
        if (Permission.write === permissionName) {
            const oldPermission = user.can_edit;
            if (false === oldPermission) {
                user.can_view = true;
            }
            user.can_edit = !oldPermission;
        }
        // You cannot unread and keeping write
        if (Permission.read === permissionName) {
            const oldPermission = user.can_view;
            if (true === oldPermission) {
                user.can_edit = false;
            }
            user.can_view = !oldPermission;
        }

        // Support loading while calling the backend
        this.loading = true;
        this.backendCallCount++;
        // Update backend
        this.$wire
            .$call('updateTeamPermission', userId, permissionName)
            .then(() => {
                this.backendCallCount--;
                if (this.backendCallCount <= 0) {
                    this.loading = false;
                }
            })
            .catch(e => handleError(e));
    },

    /**
     * Refresh the permissions
     */
    refreshPermissions(): void {
        this.$wire
            .$call('getPermissionPanelData')
            .then(users => {
                this.users = users as Array<UserDto>;
            })
            .catch(e => handleError(e));
    },
});
