import zenScroll from "zenscroll";
import config from "@/js/config.json";
import { DisplayState, BannerStatus, NotificationType } from "@/app/enums";
import Numerify from "@/app/services/Numerify";
import { TotalPanelItem, ErrorBag, PriceDisplay, LineCollection, LineErrorItem, DummyLine, PriceFieldDisplay } from '@/interfaces/DTOs/LineEdition';
import { EstimateRemark, Line, LineRemark } from "@/interfaces/DTOs/Models";
import { EstimatePermissionKeys, EstimatePermissions } from "@/interfaces/DTOs/Permissions";
import { AlpineData, LivewireData, LivewireMethods } from "@/interfaces/Components/EstimateLineReader";
import { CreatedRemarkResponse, DeletedLineResponse, DeletedRemarkResponse, DesignationResponse, GetLineResponse, InitialDataResponse, LineDocumentResponse, MetadataResponse, OverridePriceResponse, UpdatedCodeLineResponse, UpdatedPriceResponse } from "@/interfaces/Responses/EstimateLineReader";
import { createAlpineComponent, handleError, raw, translate } from "@/app/services/utils";
import * as Livewire from "@/js/interfaces/AlpineWired";

type Self = Livewire.AlpineComponentItself<AlpineData, LivewireData, LivewireMethods>;

/**
 * Important note:
 * Methods with "call" prefix call the livewire component (backend) then update frontend.
 * Methods without this previx update only the frontend.
 */
export default (
    initialEstimateVersion: number,
    initialLineCount: number,
    lesserValueUnit: string,
) => createAlpineComponent<AlpineData, LivewireData, LivewireMethods>({
    /**
     * Properties
     */
    estimate: {
        created_at: "",
        purchase_order_id: null,
    },
    estimateVersion: initialEstimateVersion,
    lineCount: initialLineCount,
    lines: [],
    estimateRemarks: [],
    lineRemarks: [],
    totalPanel: [],
    errorBag: {
        main: [],
        lines_error: {},
        lines_warning: {},
        lines_info: {},
    },
    lotsColor: {},
    staticItemsList: [],
    fileMaxSize: 0,
    priceFieldsDisplay: [],
    currentPage: 1,

    // Permissions
    permissions: {
        mustGoToFirstProcess: false,
        canGoToFirstProcess: false,
        canEditEstimateLines: false,
        canReopenEstimate: false,
        canDeleteEstimate: false,
        canAbandonEstimate: false,
        canChangeStatus: false,
        canValidateOnCos: false,
        canCreateRemark: false,
    },

    // User inputs
    estimateRemarkUserInput: "",
    lineRemarkUserInputBag: {},
    remarkUserErrorBag: {},

    // Front states
    gotAllLines: false,
    didInitialRequest: false,
    isUpdating: false,
    canGotoBottom: true,

    /** Getters */

    get linePerLoad(): number {
        return config.components.estimateLineReader.linePerLoad;
    },

    /** Methods */

    async init() {
        await this.performInitializationRequest(this.linePerLoad);
    },

    /**
     * Perform the initial request to show lines.
     * @param linePerPage The count of lines to retrieve for a page
     */
    async performInitializationRequest(linePerPage: number | null = null): Promise<void> {
        linePerPage ??= this.linePerLoad;

        // Reset some variables to a default value
        this.gotAllLines = false;
        this.didInitialRequest = false;
        this.isUpdating = false;
        this.estimateRemarks = [];
        this.lineRemarks = [];
        this.currentPage = 1;

        // Replace lines by dummies for waiting display
        // Important note, DummyLine is not used because it cause MANY errors.
        // DO NOT set this.lines as Array<Line | DummyLine>. Just ignore the error.
        // I use the technical here to set the value as unknown, to then set it as whatever i want.
        const dummies = this.createWaitingLines(this.lineCount) as unknown;
        this.lines = dummies as Array<Line>;

        const data = await this.$wire.$call(
            "getInitializationData",
            this.estimateVersion,
            linePerPage,
            this.currentPage,
        ) as InitialDataResponse;

        // Set data that often change between requests
        this.replaceLinesProperly(data.lines.items, this.currentPage);
        this.updateTotalPanelValues(data.totalPanel);
        this.updateErrorBag(data.errorBag);
        this.updatePermissions(data.permissions);

        // Set data that never change
        this.lotsColor = data.lotsColor;
        this.staticItemsList = data.staticItemsList;
        this.estimateRemarks = data.estimateRemarks;
        this.lineRemarks = data.lineRemarks;
        this.fileMaxSize = data.fileMaxSize;
        this.estimate = data.estimate;

        // Tells the program we did the initial request
        this.didInitialRequest = true;

        // Trigger the load-until only if we didnt have all the lines.
        if (data.lines.hasMorePage) {
            this.loadLinesUntilLast();
        } else {
            this.gotAllLines = true;
        }
    },

    // ----------------------
    // Global public methods
    // ----------------------

    /**
     * Reload permissions, errorBag, totalPanel and maybe other things
     *
     * @param version The estimate version
     */
    async reloadEstimateMetadata(version: number): Promise<void> {
        const data = await this.$wire.$call("getFreshEstimateMetadata", version) as MetadataResponse;
        this.updateTotalPanelValues(data.totalPanel);
        this.updatePermissions(data.permissions);
        this.updateErrorBag(data.errorBag);
    },

    /**
     * Retrieve a line index from an id.
     *
     * @param lineId The id from a line to find
     * @returns A number with the line index or null
     */
    getLineIndexFromId(lineId: number): number | null {
        const index = this.lines.findIndex((line) => line.id === lineId);
        return -1 === index ? null : index;
    },

    /**
     * Retrieve a line id from an index.
     *
     * @param lineIndex The index from a line to find
     * @returns A number with the index or null
     */
    getLineIdFromIndex(lineIndex: number): number | null {
        return this.lines[lineIndex]?.id ?? null;
    },

    /**
     * Update the current permissions
     */
    updatePermissions(newPermissions: EstimatePermissions): void {
        Object.keys(newPermissions).forEach((key) => {
            // Need to fix the error here when there is no supposed to have an error
            if (key in this.permissions) {
                this.permissions[key as keyof typeof EstimatePermissionKeys] = newPermissions[key as keyof typeof EstimatePermissionKeys];
            }
        });
    },


    // ---------------------
    // Other public methods
    // ---------------------

    /**
     * Create a display structure for price fields
     *
     * @param line The line to analyse
     */
    createPriceDisplayMapping(line: Line): PriceFieldDisplay {
        const display = {
            label: DisplayState.hidden,
            supply: line.supply_available
                ? DisplayState.show
                : DisplayState.hidden,
            drop: line.drop_available ? DisplayState.show : DisplayState.hidden,
            drop_off: line.drop_off_available
                ? DisplayState.show
                : DisplayState.hidden,
        };

        if (
            line.override_price ||
            line.support_required ||
            line.unit === lesserValueUnit
        ) {
            display.label = DisplayState.show;
            display.drop = DisplayState.remove;
            display.drop_off = DisplayState.remove;
        }

        return display;
    },

    /**
     * Create an empty price display
     */
    createEmptyPriceDisplayMapping(): PriceFieldDisplay {
        return {
            label: DisplayState.hidden,
            supply: DisplayState.hidden,
            drop: DisplayState.hidden,
            drop_off: DisplayState.hidden,
        };
    },

    /**
     * Update a price display
     *
     * @param line The line to map
     */
    updatePriceDisplayForLine(line: Line): void {
        this.priceFieldsDisplay[line.id] = this.createPriceDisplayMapping(line);
    },

    /**
     * Retrieve a price display if exists for a line or an empty display
     *
     * @param lineIndex The line index to find
     */
    getPriceDisplayFromIndex(lineIndex: number): PriceDisplay {
        const lineId = this.getLineIdFromIndex(lineIndex);
        if (null === lineId) {
            throw new Error(`Line not found from index. Given index "${lineIndex}"`);
        }
        const foundItem = this.priceFieldsDisplay[lineId];

        return undefined === foundItem
            ? this.createEmptyPriceDisplayMapping()
            : foundItem;
    },

    /**
     * Retrieve a price display from a line id
     *
     * @param lineId The line id for the price display to get
     */
    getPriceDisplayFromId(lineId: number): PriceDisplay {
        const foundItem = this.priceFieldsDisplay[lineId];

        return undefined === foundItem
            ? this.createEmptyPriceDisplayMapping()
            : foundItem;
    },

    /**
     * Change the current estimate version
     *
     * @param newVersion The estimate version to define
     * @param newLineCount The estimate dummy line number to show at line refresh
     */
    changeVersion(newVersion: number, newLineCount: number): Self {
        this.lockCurrentDocument();
        this.estimateVersion = newVersion;
        if (newLineCount) {
            this.lineCount = newLineCount;
        }
        this.currentPage = 1;

        return this;
    },

    /**
     * Reload the reader component
     */
    reloadComponent(): void {
        this.lockCurrentDocument();
        this.currentPage = 1;
        this.performInitializationRequest();
    },

    /**
     * Lock the current document permission. Need to refresh theses information.
     */
    lockCurrentDocument(): void {
        Object.keys(this.permissions).forEach(key => {
            this.permissions[key as keyof typeof EstimatePermissionKeys] = false;
        });
    },

    /**
     * Move a line from its old position to a new position
     *
     * @param lineId The line id
     * @param lineOldIndex The old line position
     * @param lineNewIndex The new line position
     */
    moveLine(lineId: number, lineOldIndex: number, lineNewIndex: number): void {
        // Reorder the dragged item in the internal list
        const lines = raw(this.lines);
        const droppedAtItem = lines.splice(lineOldIndex, 1)[0];
        lines.splice(lineNewIndex, 0, droppedAtItem);
        this.lines = lines;

        // Reassign the order property for lines AND create the list for the backend sync
        const reordered: Record<number, number> = {};
        this.lines.forEach((curLine, iterator) => {
            if (curLine.id !== lineId) {
                curLine.order = iterator;
            } else {
                curLine.order = lineNewIndex;
            }
            reordered[curLine.id] = curLine.order;
        });

        // Sync the new ordering with the backend
        this.callSyncLineOrdering(reordered);
    },

    /**
     * Reorder the estimate lines from the beginning
     */
    reorderLinesFromBeginning(updateBackend: boolean = false): void {
        const reordered: Record<number, number> = {};
        this.lines.forEach((curLine, iterator) => {
            curLine.order = iterator;
            reordered[curLine.id] = curLine.order;
        });

        if (updateBackend) {
            this.callSyncLineOrdering(reordered);
        }
    },

    /**
     * Call the backend to sync line order with given array.
     *
     * @param reordered New lines order
     */
    callSyncLineOrdering(reordered: Record<number, number>): void {
        this.isUpdating = true;
        this.$wire
            .$call("reorderLines", reordered)
            .then(() => {
                this.isUpdating = false;
            })
            .catch((err: Error) => handleError(err));
    },

    /**
     * Reload the lines property
     */
    reloadLinesProperty(): void {
        const lines = raw(this.lines);
        this.$nextTick(() => {
            this.lines = lines;
        });
    },

    /**
     * Scroll the page to the bottom content
     */
    scrollToEstimateRemarkSection(): void {
        const rect = this.getHTMLElementEstimateRemarks().getBoundingClientRect();

        zenScroll.toY(rect.top - 280);
    },

    /**
     * Retrieve the estimate-remarks tag
     */
    getHTMLElementEstimateRemarks(): HTMLElement {
        const el = document.getElementById("estimate-remarks");
        if (null === el) {
            throw new Error(`The html element with id "estimate-remarks" does not exists`);
        }
        return el;
    },

    /**
     * Checks if the user can scroll to the bottom
     */
    checkIfCanScrollToBottom(): void {
        const doc = document.documentElement;
        const top = (window.scrollY || doc.scrollTop) - (doc.clientTop || 0);

        const rect = this.getHTMLElementEstimateRemarks().getBoundingClientRect();

        this.canGotoBottom = top < rect.top;
    },

    /**
     * Retrieve the content from a reactive data
     *
     * @param reactive
     */
    toRaw(reactive: ProxyConstructor): ProxyConstructor {
        return raw(reactive);
    },

    /**
     * Create an array of dummy lines.
     *
     * @param quantity
     */
    createWaitingLines(quantity: number): Array<DummyLine> {
        return Array(quantity).fill({ not_loaded: true });
    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * Line managment
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
    /**
     * Place lines into the lines bag.
     *
     * @param lines The lines to insert
     * @param page The current page
     */
    replaceLinesProperly(lines: Array<Line>, page: number): void {
        lines.forEach((line, index) => {
            const linePosition = (page - 1) * this.linePerLoad + index;
            this.lines.splice(linePosition, 1, line);
            this.updatePriceDisplayForLine(line);
        });
    },

    /**
     * Retrieve the lines but paginated
     *
     * @param lineQuantity The count of lines to retrieve for a page
     * @param page The page to retrieve
     */
    async getPaginatedLines(lineQuantity: number = 10, page: number = 1): Promise<LineCollection> {
        return await this.$wire.$call(
            "getPaginatedLines",
            this.estimateVersion,
            lineQuantity,
            page,
        ) as LineCollection;
    },

    /**
     * Load lines until you loaded all lines.
     */
    async loadLinesUntilLast(): Promise<void> {
        this.currentPage++;
        const lineCollection = await this.getPaginatedLines(
            this.linePerLoad,
            this.currentPage,
        );
        this.replaceLinesProperly(lineCollection.items, this.currentPage);

        if (lineCollection.hasMorePage) {
            // Load more lines
            this.loadLinesUntilLast();
        } else {
            // Allows the user to update the lines
            this.gotAllLines = true;
        }
    },

    /**
     * Add a new line from an id
     *
     * @param lineId A line id to add (frontend)
     */
    addLineFromId(lineId: number): void {
        const lineIndex = this.getLineIndexFromId(lineId);

        // Do something only if the line doesnt exists
        if (null === lineIndex) {
            this.$wire
                .$call("getLine", lineId)
                .then((data: unknown) => {
                    const res = data as GetLineResponse;
                    const order = res.line.order;

                    this.lines.push(res.line);
                    this.lineCount = this.lines.length;

                    this.updatePriceDisplayForLine(res.line);
                    this.$dispatch("update-frontend-for-line-metadata", {
                        line: res.line,
                    });

                    // reorder line
                    this.lines.forEach((currentLine) => {
                        if (
                            currentLine.order >= order &&
                            currentLine.id !== res.line.id
                        ) {
                            currentLine.order++;
                        }
                    });
                    this.lines.sort((line1, line2) =>
                        line1.order > line2.order ? 1 : -1,
                    );

                    // Update internal error bag
                    const errorBag = res.errorBag;
                    this.updateErrorBagFromDataAndLineId(errorBag, lineId);

                    this.isUpdating = false;
                })
                .catch((err: Error) => handleError(err));
        }
    },

    /**
     * Remove an estimate line (in frontend)
     *
     * @param lineId A line id to remove
     */
    hardDeleteLineFromId(lineId: number): void {
        const lineIndex = this.getLineIndexFromId(lineId);
        if (null === lineIndex) {
            throw `The line with id ${lineId} cannot be found`;
        }
        this.hardDeleteLineFromIndex(lineIndex, lineId);
        this.removeErrorBagLineFromId(lineId);
    },

    /**
     * Remove an estimate line (in frontend)
     *
     * @param lineIndex A line index to remove
     * @param lineId An optional argument to gain performance if already has line id
     */
    hardDeleteLineFromIndex(lineIndex: number, lineId: number | null = null): void {
        // Remove the line from the array
        this.lines.splice(lineIndex, 1);
        this.lineCount = this.lines.length;

        // The following will remove the associated line remarks
        if (null === lineId) {
            // If lineId equals null even after search, that will do not trigger any error
            lineId = this.getLineIdFromIndex(lineIndex);

            if (null === lineId) {
                throw new Error(`Line id doesnt exists. Given line index ${lineIndex}`);
            }
        }

        this.removeAssociatedLineRemarkWithLineId(lineId);
        this.removeErrorBagLineFromId(lineId);

        const updateBackend = false;
        this.reorderLinesFromBeginning(updateBackend);
        this.reloadEstimateMetadata(this.estimateVersion);
    },

    /**
     * Soft delete a line from an index (frontend)
     *
     * @param lineIndex The line index to soft delete
     * @param ifSoftDeleteItInNextVersion If soft deleted, delete it in next version
     */
    softDeleteLineFromIndex(lineIndex: number, ifSoftDeleteItInNextVersion: boolean = false): void {
        this.lines[lineIndex].not_in_next_version = true;
        if (ifSoftDeleteItInNextVersion) {
            this.lines[lineIndex].delete_in_next_version = true;
        }
    },

    /**
     * Unsoft delete/restore a line from an index (frontend)
     *
     * @param lineIndex The line index to soft delete
     */
    restoreLineFromIndex(lineIndex: number): void {
        this.lines[lineIndex].not_in_next_version = false;
        this.lines[lineIndex].delete_in_next_version = false;
    },

    /**
     * Unsoft delete/restore a line from an index (frontend)
     *
     * @param lineId The line index to soft delete
     */
    restoredLineFromId(lineId: number): void {
        const lineIndex = this.getLineIndexFromId(lineId);
        if (null === lineIndex) {
            throw new Error(`Line index doesnt exists. Given line id ${lineId}`);
        }

        this.restoreLineFromIndex(lineIndex);
        this.reloadEstimateMetadata(this.estimateVersion);
    },

    /**
     * Soft delete a line from an index (frontend and backend)
     *
     * @param lineIndex The line index to soft delete
     */
    async callSoftDeleteLineFromIndex(lineIndex: number): Promise<void> {
        const lineId = this.lines[lineIndex].id;
        this.isUpdating = true;

        try {
            const data = await this.$wire.$call("softDeleteLine", lineId, true) as DeletedLineResponse;
            this.updateTotalPanelValues(data.totalPanel);

            // Change the line display (frontend)
            this.softDeleteLineFromIndex(lineIndex);

            // Update internal error bag
            const errorBag = data.errorBag;
            this.updateErrorBagFromDataAndLineId(errorBag, lineId);

            // Tell the top component to recalculate the global price
            this.$wire.$dispatch("calculateGlobalPrice");
            this.isUpdating = false;
        } catch (err) {
            handleError(err as Error);
        }
    },

    /**
     * Unsoft delete/restore a line from an index (frontend and backend)
     *
     * @param lineIndex The line index to restore (in frontend and backend)
     */
    async callRestoreLineFromIndex(lineIndex: number): Promise<void> {
        const lineId = this.lines[lineIndex].id;
        this.isUpdating = true;

        try {
            const data = await this.$wire.$call("softDeleteLine", lineId, false) as DeletedLineResponse;
            this.updateTotalPanelValues(data.totalPanel);

            // Change the line display (frontend)
            this.restoreLineFromIndex(lineIndex);

            // Update internal error bag
            const errorBag = data.errorBag;
            this.updateErrorBagFromDataAndLineId(errorBag, lineId);
            // Tell the top component to recalculate the global price
            this.$wire.$dispatch("calculateGlobalPrice");
            this.isUpdating = false;
        } catch (err) {
            handleError(err as Error);
        }
    },

    /**
     * Trigger the openning of the line deletion modal
     *
     * @param lineIndex The line index you wanna delete
     */
    openLineDeletionModal(lineIndex: number): void {
        this.isUpdating = true;
        this.$wire
            .$call("setLineToDeleteIt", this.lines[lineIndex].id)
            .then(() => {
                this.isUpdating = false;
            })
            .catch((err: Error) => handleError(err));
    },

    /**
     * Hard delete, soft delete or restore a line in backend and frontend from a line index.
     *
     * @param lineIndex The line index in the list (frontend and backend)
     */
    async callSoftOrHardDeleteOrRestoreLineFromIndex(lineIndex: number): Promise<void> {
        // If the line is not new from this version, we could soft delete it
        if (!this.lines[lineIndex].add_in_this_version) {
            // We can know if a line should be soft deleted or restore from the not_in_next_version property.
            const shouldSoftDelete = !this.lines[lineIndex].not_in_next_version;

            if (shouldSoftDelete) {
                await this.callSoftDeleteLineFromIndex(lineIndex);
            } else {
                await this.callRestoreLineFromIndex(lineIndex);
            }
            // We should trigger the SelectSlipItems livewire component and tells him to reload its collection
            this.$wire.$dispatch("SelectSlipItems", "updateSlipList");
        } else {
            // Otherwise, we should delete it
            this.openLineDeletionModal(lineIndex);
        }
    },

    /**
     * Soft delete/delete a line in frontend.
     *
     * @param lineId The line id to delete/soft delete (frontend)
     * @param shouldSoftDelete Tells if should hard of soft delete a line
     * @param ifSoftDeleteItInNextVersion If soft deleted, delete it in next version
     */
    softOrHardDeleteLineFromId(lineId: number, shouldSoftDelete: boolean, ifSoftDeleteItInNextVersion: boolean): void {
        const lineIndex = this.getLineIndexFromId(lineId);

        if (null !== lineIndex) {
            if (shouldSoftDelete) {
                this.softDeleteLineFromIndex(
                    lineIndex,
                    ifSoftDeleteItInNextVersion,
                );
            } else {
                this.hardDeleteLineFromIndex(lineIndex);
            }
            this.reloadEstimateMetadata(this.estimateVersion);
        }
    },

    /**
     * Describe what happen when line is updated frontside
     *
     * @param lineIndex
     */
    markLineAsUpdatedFromIndex(lineIndex: number): void {
        this.lines[lineIndex].has_new_or_dirty_line_or_remark = true;
    },

    /**
     * Toggle the action to override a line price from a line index.
     *
     * @param lineIndex The line index to "override"
     */
    callToggleOverridePriceFromIndex(lineIndex: number): void {
        const lineId = this.getLineIdFromIndex(lineIndex);
        if (null === lineId) {
            throw new Error(`Line not found from index. Given index "${lineIndex}"`);
        }


        this.isUpdating = true;
        this.$wire
            .$call("toggleOverridePrice", lineId)
            .then((data) => {
                const res = data as OverridePriceResponse;

                this.isUpdating = false;

                // Update the line
                this.lines[lineIndex] = res.updatedLine;
                this.updatePriceDisplayForLine(res.updatedLine);
                this.$dispatch("update-frontend-for-line-metadata", {
                    line: res.updatedLine,
                });

                // Update the total
                this.updateTotalPanelValues(res.totalPanel);

                // Update internal error bag
                const errorBag = res.errorBag;
                this.updateErrorBagFromDataAndLineId(errorBag, lineId);
                this.updatePermissions(res.permissions);
            })
            .catch((err: Error) => handleError(err));
    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * Line remark managment
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
     * Add a line remark to the lineRemarks container
     * @param lineId The line id to update
     * @param remark The remark message to update
     */
    addLineRemark(lineId: number, remark: LineRemark): void {
        // Fix bug where the line doesnt exists
        if (this.lineRemarks[lineId] === undefined) {
            this.lineRemarks[lineId] = [];
        }
        this.lineRemarks[lineId].push(remark);

        const lineIndex = this.getLineIndexFromId(lineId);
        if (null === lineIndex) {
            throw new Error(`Line index doesnt exists. Given line id ${lineId}`);
        }
        this.lines[lineIndex].has_new_or_dirty_line_or_remark = true;
    },

    /**
     * Remove a line remark from an id (frontend and backend)
     *
     * @param lineRemarkId The line remark id to remove
     */
    callRemoveLineRemarkFromId(lineRemarkId: number, lineId: number): void {
        this.isUpdating = true;

        this.removeLineRemarkFromId(lineRemarkId, lineId);
        this.$wire
            .$call("deleteLineRemarkFromId", lineRemarkId, lineId)
            .then((data) => {
                const res = data as DeletedRemarkResponse;

                // Update internal error bag
                const errorBag = res.errorBag;
                this.updateErrorBagFromDataAndLineId(errorBag, lineId);
                this.updatePermissions(res.permissions);

                this.isUpdating = false;
            })
            .catch((err: Error) => handleError(err));
    },

    /**
     * Remove the line remark from the frontend bag (frontend)
     *
     * @param lineRemarkId The id of the line remark to remove
     * @param lineId The line id to find where the remark is stored
     */
    removeLineRemarkFromId(lineRemarkId: number, lineId: number): void {
        const remarkIndexInBag = this.lineRemarks[lineId].findIndex(
            (lineRemark) => lineRemark.id === lineRemarkId,
        );
        this.lineRemarks[lineId].splice(remarkIndexInBag, 1);
    },

    /**
     * Remove all the associated line remarks with a given line id
     *
     * @param lineId The line id associated with all the line remarks
     */
    removeAssociatedLineRemarkWithLineId(lineId: number): void {
        delete this.lineRemarks[lineId];
    },

    /**
     * Retrieve line remarks conditionnaly
     *
     * @param lineId The line id to find where the remark is stored
     * @param showAll Choose to show all lines remarks
     * @param lineRemarkLimitedQuantity The quantity of line remarks to show
     */
    getLineRemarks(lineId: number, showAll: boolean, lineRemarkLimitedQuantity: number): Array<LineRemark> {
        return 0 < this.lineRemarks[lineId]?.length
            ? showAll
                ? this.lineRemarks[lineId]
                : this.lineRemarks[lineId]?.slice(0, lineRemarkLimitedQuantity)
            : [];
    },

    /**
     * Update our buffer memory for line remarks so after we can send it to the backend.
     *
     * @param lineId The line id to update (in backend)
     */
    updateLineRemarkBufferMemory(lineId: number): void {
        const value = (this.$el as HTMLInputElement).value;

        // Manage input error
        const errorMessages = [];

        if (value.length < config.components.estimateLineReader.lineRemarkInput.min) {
            errorMessages.push(
                translate(`Your remark should be higher than :min characters`, {
                    'min': config.components.estimateLineReader.lineRemarkInput.min.toString(),
                }),
            );
        }


        if (value.length > config.components.estimateLineReader.lineRemarkInput.max) {
            errorMessages.push(
                translate(`Your remark should be lower than :max characters`, {
                    'max': config.components.estimateLineReader.lineRemarkInput.max.toString(),
                }),
            );
        }

        // Force to remove any error previously displayed
        if (0 === errorMessages.length) {
            this.removeLineRemarkError(lineId);
        }

        // If there was any error message
        if (0 !== errorMessages.length) {
            this.remarkUserErrorBag[lineId] = [];
            errorMessages.forEach((message) => {
                this.remarkUserErrorBag[lineId].push(message);
            });
        }

        this.lineRemarkUserInputBag[lineId] = value;
    },

    /**
     * Ask the backend to add a line remark from our buffer memory.
     *
     * @param lineId The line id to update
     */
    callAddLineRemarkFromBufferMemory(lineId: number): void {
        const value =
            this.lineRemarkUserInputBag[lineId] !== undefined
                ? this.lineRemarkUserInputBag[lineId]
                : "";

        // We only add remark when the field is not empty
        if (!this.hasAnyLineRemarkError(lineId)) {
            delete this.lineRemarkUserInputBag[lineId];
            (this.$el as HTMLInputElement).value = "";

            this.isUpdating = true;
            this.$wire
                .$call("addLineRemark", lineId, value)
                .then((data) => {
                    const res = data as CreatedRemarkResponse<LineRemark>;

                    if (res.newRemark) {
                        this.addLineRemark(lineId, res.newRemark);
                    }
                    if (res.errorBag) {
                        // Update internal error bag
                        const errorBag = res.errorBag;
                        this.updateErrorBagFromDataAndLineId(errorBag, lineId);
                    }
                    if (res.permissions) {
                        this.updatePermissions(res.permissions);
                    }

                    this.isUpdating = false;
                })
                .catch((err: Error) => handleError(err));
        }
    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * Document upload
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
     * Show a document from its id
     *
     * @param documentId The document id to open
     */
    openLineDocument(documentId: number): void {
        this.$wire
            .$call("getRouteToShowDocument", documentId)
            .then((data) => {
                const res = data as LineDocumentResponse;

                window.open(res.url, "_blank");
            })
            .catch((err: Error) => handleError(err));
    },

    /**
     * Triggered when a file has been updated
     *
     * @param lineId The number id that has a file
     * @param filename The document name that was affected to a line
     * @param fileId The id of the attached document
     * @param errorBag The error bag retrieved after the file has been attached
     */
    fileHasBeenAttached(lineId: number, filename: string, documentId: number, errorBag: ErrorBag): void {
        const lineIndex = this.getLineIndexFromId(lineId);
        if (null !== lineIndex) {
            this.lines[lineIndex].document_id = documentId;
            this.lines[lineIndex].document_name = filename;
        }
        this.updateErrorBagFromDataAndLineId(errorBag, lineId);
    },

    /**
     * Call a modal that ask to dissociate or not a document from a line.
     *
     * @param documentId The id of the document to remove
     * @param lineId The line impacted
     */
    callDissociateDocumentFromId(documentId: number, lineId: number): void {
        this.isUpdating = true;
        this.$wire.$call("setDeleteFileId", documentId, lineId).then(() => {
            this.isUpdating = false;
        });
    },

    /**
     * Triggered when a document attached to a file has been removed
     *
     * @param lineId The id of the line that has its file deleted
     * @param successMessage A success message to show
     * @param errorBag The error bag retrieved after the file has been attached
     */
    fileHasBeenDissociated(lineId: number, successMessage: string, errorBag: ErrorBag): void {
        const lineIndex = this.getLineIndexFromId(lineId);
        if (null !== lineIndex) {
            this.$dispatch("banner-message", {
                style: BannerStatus.Success,
                message: successMessage,
            });
            this.lines[lineIndex].document_id = null;
            this.lines[lineIndex].document_name = null;
        }
        this.updateErrorBagFromDataAndLineId(errorBag, lineId);
    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * Error bag managment
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
     * Perform a request to refresh the total panel data.
     */
    async reloadErrorBag(): Promise<void> {
        const errorBag = await this.$wire.$call(
            "getEstimateErrorContent",
            this.estimateVersion,
        ) as ErrorBag;
        this.updateErrorBag(errorBag);
    },

    /**
     * Checks if a line error exists for a line from its id
     *
     * @param lineId The line id to retrieve any error
     */
    hasLineErrorFromLineId(lineId: number): boolean {
        return this.errorBag.lines_error[lineId] !== undefined;
    },

    /**
     * Checks if a compatibility error exists for a line from its id
     *
     * @param lineId The line id to retrieve any error
     */
    hasLineWarningFromLineId(lineId: number): boolean {
        return this.errorBag.lines_warning[lineId] !== undefined;
    },

    /**
     * Checks if a compatibility error exists for a line from its id
     *
     * @param lineId The line id to retrieve any error
     */
    hasLineInfoFromLineId(lineId: number): boolean {
        return this.errorBag.lines_info[lineId] !== undefined;
    },

    /**
     * Checks if any kind of error exists for a line from its id
     *
     * @param lineId The line id to retrieve any error
     */
    hasAnyLineNoticeFromLineId(lineId: number): boolean {
        return (
            this.hasLineErrorFromLineId(lineId) ||
            this.hasLineWarningFromLineId(lineId) ||
            this.hasLineInfoFromLineId(lineId)
        );
    },

    /**
     * Checks if there is any error in the error bag.
     */
    hasAnyNotice(): boolean {
        return (
            0 !== this.errorBag.main.length ||
            0 !== Object.keys(this.errorBag.lines_error).length ||
            0 !== Object.keys(this.errorBag.lines_warning).length ||
            0 !== Object.keys(this.errorBag.lines_info).length
        );
    },

    /**
     * Checks if there is any error in the error bag.
     *
     * @param lineId The line id to retrieve any error
     */
    hasAnyLineNotice(lineId: number): boolean {
        return (
            0 !==
            (this.getLineErrorObjectForLine(lineId)?.errors?.length ?? 0) ||
            0 !==
            (this.getLineWarningObjectForLine(lineId)?.errors?.length ??
                0) ||
            0 !== (this.getLineInfoObjectForLine(lineId)?.errors?.length ?? 0)
        );
    },

    /**
     * Checks if there is any blocking error in the error bag.
     */
    hasAnyBlockingError(): boolean {
        return (
            0 !== this.errorBag.main.length ||
            0 !== Object.keys(this.errorBag.lines_error).length
        );
    },

    /**
     * Retrieve any kind of error for a line from its id
     *
     * @param lineId The line id to retrieve any error
     */
    getNoticesFromLineId(lineId: number): Array<string> {
        let messages: Array<string> = [];

        if (this.hasLineErrorFromLineId(lineId)) {
            messages = messages.concat(
                this.getLineErrorObjectForLine(lineId)?.errors,
            );
        }
        if (this.hasLineWarningFromLineId(lineId)) {
            messages = messages.concat(
                this.getLineWarningObjectForLine(lineId)?.errors,
            );
        }
        if (this.hasLineInfoFromLineId(lineId)) {
            messages = messages.concat(
                this.getLineInfoObjectForLine(lineId)?.errors,
            );
        }

        return messages;
    },

    /**
     * Retrieve the line error object
     *
     * @param lineId The line id to retrieve any error
     */
    getLineErrorObjectForLine(lineId: number): LineErrorItem {
        return this.errorBag.lines_error[lineId];
    },

    /**
     * Retrieve the line warning object
     *
     * @param lineId The line id to retrieve any error
     */
    getLineWarningObjectForLine(lineId: number): LineErrorItem {
        return this.errorBag.lines_warning[lineId];
    },

    /**
     * Retrieve the line info object
     *
     * @param lineId The line id to retrieve any error
     */
    getLineInfoObjectForLine(lineId: number): LineErrorItem {
        return this.errorBag.lines_info[lineId];
    },

    /**
     * Retrieve the logo class (for the warn sign) for a line (with id)
     * @param lineId The line id to retrieve any error
     */
    getExclamationTriangleLogoClassFromLineId(lineId: number): string {
        switch (this.getMostImportantNoticeGroupFromLineId(lineId)) {
            case NotificationType.error:
                return config.global.notificationType.class.error;
            case NotificationType.warning:
                return config.global.notificationType.class.warning;
            case NotificationType.info:
                return config.global.notificationType.class.info;
        }
        return "";
    },

    /**
     * Retrieve the most important notice group for a line
     * @param lineId The line id to retrieve any error
     */
    getMostImportantNoticeGroupFromLineId(lineId: number): string {
        return this.hasLineErrorFromLineId(lineId)
            ? NotificationType.error
            : this.hasLineWarningFromLineId(lineId)
                ? NotificationType.warning
                : this.hasLineInfoFromLineId(lineId)
                    ? NotificationType.info
                    : "";
    },

    /**
     * Update the core error bag
     *
     * @param errorBag
     */
    updateErrorBag(errorBag: ErrorBag): void {
        this.errorBag.main = errorBag.main;
        this.errorBag.lines_error = { ...errorBag.lines_error };
        this.errorBag.lines_warning = { ...errorBag.lines_warning };
        this.errorBag.lines_info = { ...errorBag.lines_info };
    },

    /**
     * Update the internal error bag from data.
     *
     * @param errorBag A requested error bag
     * @param lineId The line id that should have its error bag updated
     */
    updateErrorBagFromDataAndLineId(errorBag: ErrorBag, lineId: number): void {
        this.errorBag.main = errorBag.main;

        if (undefined !== errorBag.lines_error[lineId]) {
            this.errorBag.lines_error[lineId] = {
                ...errorBag.lines_error[lineId],
            };
        } else {
            delete this.errorBag.lines_error[lineId];
        }
        if (undefined !== errorBag.lines_warning[lineId]) {
            this.errorBag.lines_warning[lineId] = {
                ...errorBag.lines_warning[lineId],
            };
        } else {
            delete this.errorBag.lines_warning[lineId];
        }
        if (undefined !== errorBag.lines_info[lineId]) {
            this.errorBag.lines_info[lineId] = {
                ...errorBag.lines_info[lineId],
            };
        } else {
            delete this.errorBag.lines_info[lineId];
        }
    },

    /**
     * Remove a line in the error bag from a line id
     *
     * @param lineId The line id to remove
     */
    removeErrorBagLineFromId(lineId: number): void {
        delete this.errorBag.lines_error[lineId];
        delete this.errorBag.lines_warning[lineId];
        delete this.errorBag.lines_info[lineId];
    },

    /**
     * Checks if there is any error for a line remark input
     *
     * @param lineId The line remark id in the error bag
     */
    hasAnyLineRemarkError(lineId: number): boolean {
        return (
            lineId in this.remarkUserErrorBag &&
            0 !== this.remarkUserErrorBag[lineId].length
        );
    },

    /**
     * Retrieve any error for a line remark input
     *
     * @param lineId The line remark id in the error bag
     */
    getLineRemarkErrors(lineId: number): string {
        return this.remarkUserErrorBag[lineId].join(", ");
    },

    /**
     * Remove a line remark error.
     *
     * @param lineId Line id
     */
    removeLineRemarkError(lineId: number): void {
        delete this.remarkUserErrorBag[lineId];
    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * Estimate remarks
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
     * Call the livewire method to add a new estimate remark then add this new remark to our bag.
     */
    callAddEstimateRemark(): void {
        if ("" !== this.estimateRemarkUserInput) {
            this.isUpdating = true;
            this.$wire
                .$call("addEstimateRemark", this.estimateRemarkUserInput)
                .then((data) => {
                    const res = data as CreatedRemarkResponse<EstimateRemark>;

                    // Reset the input field
                    this.estimateRemarkUserInput = "";

                    this.estimateRemarks.push(res.newRemark);

                    this.updateErrorBag(res.errorBag);
                    this.updatePermissions(res.permissions);

                    this.isUpdating = false;
                });
        }
    },

    /**
     * Remove a line remark from an id
     *
     * @param remarkId The remark id to remove
     */
    callRemoveEstimateRemarkFromId(remarkId: number): void {
        this.isUpdating = true;
        this.$wire.$call("deleteEstimateRemark", remarkId).then((data) => {
            const res = data as DeletedRemarkResponse;

            const index = this.getEstimateRemarkIndexFromId(remarkId);
            if (null !== index) {
                this.estimateRemarks.splice(index, 1);
            }

            this.updateErrorBag(res.errorBag);
            this.updatePermissions(res.permissions);

            this.isUpdating = false;
        });
    },

    /**
     * Private methods
     */

    /**
     * Retrieve an estimate remark index from an id.
     *
     * @param lineId The id from a line to find
     */
    getEstimateRemarkIndexFromId(remarkId: number): number | null {
        const index = this.estimateRemarks.findIndex(
            (remark) => remark.id === remarkId,
        );
        return -1 === index ? null : index;
    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * canUpdateMainFields
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
     * Update a line code line
     *
     * @param lineId The line id to update (in backend)
     * @param lineIndex The line index to update (in frontend)
     */
    updateCodeLine(lineId: number, lineIndex: number): void {
        const value = (this.$el as HTMLInputElement).value;

        // Update internal bag
        this.lines[lineIndex].code_line = value;

        this.isUpdating = true;
        this.$wire
            .$call("updateCodeLine", lineId, value)
            .then((data) => {
                const res = data as UpdatedCodeLineResponse;

                this.markLineAsUpdatedFromIndex(lineIndex);

                // Update internal error bag
                const errorBag = res.errorBag;
                this.updateErrorBagFromDataAndLineId(errorBag, lineId);
                this.updatePermissions(res.permissions);

                // Update the current line
                this.lines[lineIndex] = res.line;

                this.isUpdating = false;
            })
            .catch((err: Error) => handleError(err));
    },

    /**
     * Update a line designation
     *
     * @param lineId The line id to update (in backend)
     * @param lineIndex The line index to update (in frontend)
     */
    updateDesignation(lineId: number, lineIndex: number): void {
        const value = (this.$el as HTMLInputElement).value;

        // Update internal bag
        this.lines[lineIndex].designation = value;

        this.isUpdating = true;
        this.$wire
            .$call("updateDesignation", lineId, value)
            .then((data) => {
                const res = data as DesignationResponse;

                this.markLineAsUpdatedFromIndex(lineIndex);

                // Update internal error bag
                const errorBag = res.errorBag;
                this.updateErrorBagFromDataAndLineId(errorBag, lineId);
                this.updatePermissions(res.permissions);

                this.isUpdating = false;
            })
            .catch((err: Error) => handleError(err));
    },

    /**
     * Update a line price for any kind of price (works from an input element)
     *
     * @param field Name of the updated field
     * @param lineId The line id to update (in backend)
     * @param lineIndex The line index to update (in frontend)
     */
    updatePriceField(field: "supply" | "drop" | "drop_off", lineId: number, lineIndex: number): undefined {
        // Runtime check. VERY IMPORTANT
        const authorizedFields = ["supply", "drop", "drop_off"];
        if (!authorizedFields.includes(field)) {
            throw "You cannot update a field that doesnt exists.";
        }

        // Retrieve input current value
        const el = (this.$el as HTMLInputElement);
        el.value = "" === el.value ? "0" : Numerify.forceEnglishFloatNotation(el.value);
        const parsedValue = parseFloat(el.value);


        if (!Numerify.isValidFloat(parsedValue)) {
            // Replace new invalid value by old valid value
            (this.$el as HTMLInputElement).value = (this.lines[lineIndex][field] ?? 0).toString();
            return;
        }
        // Parse after all the checks

        // Update internal price field
        this.lines[lineIndex][field] = parsedValue;

        this.isUpdating = true;
        this.$wire
            .$call("updatePrice", field, lineId, parsedValue)
            .then((data) => {
                const res = data as UpdatedPriceResponse;

                this.markLineAsUpdatedFromIndex(lineIndex);

                this.updateTotalPanelValues(res.totalPanel);
                // Register the generated remark
                this.addLineRemark(lineId, res.newRemark);
                // Update formated version of the line total price column
                this.lines[lineIndex].formatted_total = res.formattedTotal;

                // Update internal error bag
                const errorBag = res.errorBag;
                this.updateErrorBagFromDataAndLineId(errorBag, lineId);
                this.updatePermissions(res.permissions);

                this.isUpdating = false;
                this.$wire.$dispatch("calculateGlobalPrice");
                this.$wire.$dispatch("refreshEstimate");
            })
            .catch((err: Error) => handleError(err));

    },

    /**
     * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
     * Total panel managment
     * <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
     */
    /**
     * Perform a request to refresh the total panel data.
     */
    async reloadToTalPannel(): Promise<void> {
        const values = await this.$wire.$call(
            "getCalculatedTotalPanelData",
            this.estimateVersion,
        ) as Array<TotalPanelItem>;
        this.updateTotalPanelValues(values);
    },

    /**
     * Update the total panel property.
     *
     * @param values
     */
    updateTotalPanelValues(values: Array<TotalPanelItem>) {
        this.totalPanel = values;
    },
});
