import { App, Component, createApp } from 'vue';
import { listen, NavigateEvent } from '@/app/services/utils';


interface VueInstance {
    id: string,
    component: Component,
    instance?: App<Element>,
    mounted: boolean,
}

interface RouterItem {
    id: string,
    view: Component
}

/**
 * This class allows to register a router and the associated events to allows Vuejs to works with Livewire.
 */
export class VueLivewireRouter {
    routes: Map<string, Array<RouterItem>>;
    apps: Set<VueInstance>;

    constructor() {
        this.routes = new Map();
        this.apps = new Set();
    }

    /**
     * Add a route with an array of items.
     */
    addRoute(pathname: string, routeItems: Array<RouterItem>) {
        this.routes.set(pathname, routeItems);
    }

    /**
     * Listen all the events for the current page.
     *
     * Return all the created events for a personal deletion.
     */
    listen(): Array<() => void> {
        return [
            this.listenNavigate(),
            this.listenDomLoaded(),
            this.listenNavigated(),
        ];
    }

    /**
     * Listen the DOMContentLoaded event
     */
    private listenDomLoaded() {
        return listen('DOMContentLoaded', () => {
            this.createInstanceIfRouteMatch(window.location.pathname);
        });
    }

    /**
     * Listen the livewire:navigate event
     */
    private listenNavigate() {
        return listen('livewire:navigate', (customEvent: CustomEvent<NavigateEvent>) => {
            const context = customEvent.detail;

            // Unmount then remove any existing instance
            this.apps.forEach(instance => {
                instance.instance?.unmount();
            });
            this.apps.clear();

            this.createInstanceIfRouteMatch(context.url.pathname);
        });
    }

    /**
     * Listen the livewire:navigated event
     */
    private listenNavigated() {
        return listen('livewire:navigated', () => {
            this.searchAppToMount();
        });
    }

    /**
     * Append to the queue any instance that should be created
     */
    private createInstanceIfRouteMatch(pathname: string) {
        if (this.routes.has(pathname)) {
            const routerItems = this.routes.get(pathname) as Array<RouterItem>;

            routerItems.forEach(item => {
                this.apps.add({
                    id: item.id,
                    component: item.view,
                    mounted: false,
                });
            });
        }
    }

    /**
     * Search for any app to mount
     */
    private searchAppToMount() {
        this.apps.forEach((app) => {
            if (false === app.mounted) {
                app.instance = createApp(app.component);
                app.instance?.mount(app.id);
                app.mounted = true;
            }
        });
    }
}
