import { createAlpineComponent } from '@/app/services/utils';
import { AlpineData } from "@/interfaces/Components/SelectUnique";
import is from '../../services/is';

export default (
    livewireData: string,
    list: Array<string> = [],
    defaultValue: unknown = '',
    valueIfEmpty: null | string = null,
    disabled: boolean = false,
) => createAlpineComponent<AlpineData, Record<string, unknown>, Record<string, unknown>>({
    open: false,
    value: '',
    defaultValue: '',
    list: list,
    livewireData: livewireData,
    disabled: disabled,

    init() {
        if (is.number(defaultValue)) {
            this.value = (defaultValue as number).toString();
        } else if (is.string(defaultValue)) {
            this.value = defaultValue;
        } else {
            this.value = '';
        }

        this.$watch('value', (value: string) => {
            this.publish(value);
        });
    },

    /**
     * Detect if should add or remove a value
     */
    assignOrRemoveValue(val: string) {
        if (this.value !== val) {
            this.assignValue(val);
        } else {
            this.removeValue();
        }
    },

    /**
     * Add a value in the select bag
     */
    assignValue(val: string): void {
        this.value = val;
        this.close();
    },

    /**
     * Remove a value from the select bag
     */
    removeValue(): void {
        this.value = '';
    },

    /**
     * Checks if a value is already selected
     */
    isSelected(val: string): boolean {
        return this.value === val;
    },

    /**
     * Update our value into livewire (the backend)
     */
    publish(value: string): void {
        if (!this.disabled) {
            this.$wire.$set(this.livewireData, value ? value : valueIfEmpty);
        }
    },

    /**
     * Reset the values bag.
     */
    reset(): void {
        this.value = this.defaultValue;
    },

    /**
     * Close the selection box.
     */
    close(): void {
        this.open = false;
    },
});
