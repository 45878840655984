import Translator from "@/app/services/Translator";
import * as Livewire from "@/interfaces/AlpineWired";
// @ts-expect-error Livewire do not use typescript
import { Alpine as AlpineImport } from "@/livewire";
const Alpine = AlpineImport as TAlpine;

export function getEnumValues(enumerator: object): Array<string> {
    return Object.keys(enumerator).filter((item) => {
        return isNaN(Number(item));
    });
}

const translator = new Translator();

/**
 * Translate a message
 * @param message
 * @param params
 */
export function translate(message: string, params: Record<string, string> = {}): string {
    return translator.translate(message, params);
}

/**
 * Perform a clone of a string.
 *
 * Since Javascript use references everywhere, it is better
 * to clone a string intented to be reused.
 * @param originalString
 */
export function copyString(originalString: string) {
    return (' ' + originalString).slice(1);
}

/**
 * Global application way to handle error
 */
export function handleError(err: Error): never {
    if (false === import.meta.env.PROD) {
        throw err;
    }
    alert('An error has occured. Please contact an administrator');
    throw "An error has occured. Please contact an administrator";
}

/**
 * Raw the content of an Alpinejs component
 * @param obj
 */
export function raw<Type>(obj: Type): Type {
    return Alpine.raw<Type>(obj);
}

/**
 * Retrieve the nearest livewire component or null
 * @param el
 */
export function nearestLivewireComponent(el: HTMLElement): Livewire.LivewireHTMLElement | null {
    const component = Alpine.findClosest(el, (i: HTMLElement) => "__livewire" in i);
    if (!component) {
        return null;
    }

    return component as Livewire.LivewireHTMLElement;
}

/**
 * Create an AlpineJs component. It ensures the developper pass the Alpine data, and can register any livewire data and methods.
 * @param component
 * @returns
 */
export function createAlpineComponent<AlpineData, LivewireData, LivewireMethods>(component: Livewire.AlpineComponent<AlpineData, LivewireData, LivewireMethods>) {
    return component;
}

type Listener = (customEvent: CustomEvent) => void;

/**
 * Listen an event from window.
 */
export function listen(event: string, listener: Listener): () => void {
    window.addEventListener(event, (listener) as EventListener);

    return (() => window.removeEventListener(event, (listener) as EventListener));
}

/**
 * Execute event listeners on page unload
 */
export function unlistenOnUnload(listeners: Array<() => void>): void {
    window.addEventListener('onbeforeunload', () => {
        listeners.forEach(listener => {
            listener();
        });
    });
}

export interface NavigateEvent {
    /** A URL object of the intended destination of the navigation */
    url: URL,
    /** A boolean [true/false] indicating whether or not this navigation was triggered by a back/forward (history state) navigation */
    history: boolean,
    /** A boolean [true/false] indicating whether or not there is cached version of this page to be used instead of fetching a new one via a network round-trip */
    cached: boolean,
}
