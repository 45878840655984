export interface LivewireResponseInterface<TBody> {
    status: number;
    body: TBody;
}

export default class <TBody> implements LivewireResponseInterface<TBody> {
    status: number;
    body: TBody;

    /**
     * Create a custom livewire response
     * @param response An ajax response from Livewire
     */
    constructor(response: LivewireResponseInterface<TBody>) {
        this.status = response.status;
        this.body = response.body;
    }

    /**
     * Retrieve the response status
     */
    getStatus(): number {
        return this.status;
    }

    /**
     * Retrieve the response body
     */
    getBody(): TBody {
        return this.body;
    }

    /**
     * Checks the response status is ok
     */
    isOk(): boolean {
        return this.status >= 200 && this.status < 300;
    }

    /**
     * Checks the response status is not ok
     */
    isServerError(): boolean {
        return this.status >= 500 && this.status < 600;
    }
}
