import { InvalidArgumentException } from '@/app/exceptions';

export default {
    object<T = object>(value: unknown): value is T {
        return (
            null !== value &&
            'object' === typeof value &&
            !Array.isArray(value) &&
            !(value instanceof RegExp) &&
            !(value instanceof Date) &&
            !(value instanceof Set) &&
            !(value instanceof Map)
        );
    },
    array<Type = unknown>(value: unknown): value is Array<Type> {
        return null !== value && undefined !== value && Array.isArray(value);
    },
    string(value: unknown): value is string {
        return (
            null !== value && undefined !== value && 'string' === typeof value
        );
    },
    number(value: unknown): value is number {
        return (
            null !== value && undefined !== value && 'number' === typeof value
        );
    },
    empty(value: unknown): boolean {
        if (!this.string(value) && !this.array(value)) {
            throw new InvalidArgumentException('Given value is neither a string nor an array');
        }
        return 0 === value.length;
    },
};
