import WorkflowComponent from '@/app/components/vuejs/EstimateProcessesWorkflow.vue';
import EstimateListingComponent from '@/app/components/vuejs/EstimateListingApp.vue';
import { unlistenOnUnload } from '@/app/services/utils';
import { VueLivewireRouter } from '@/app/services/VueLivewireRouter';

const router = new VueLivewireRouter();
router.addRoute('/process', [{
    id: '#vue-app',
    view: WorkflowComponent,
}]);
router.addRoute('/estimate', [{
    id: '#estimate-listing-app',
    view: EstimateListingComponent,
}]);

unlistenOnUnload(router.listen());
