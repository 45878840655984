export enum ExportStatus {
    WaitingUserAction = "waiting-user-action",
    GeneratingFile = "generating-file",
    GotAnError = "got-an-error",
    Done = "done",
}

export const BannerStatus: Record<string, string> = {
    Success: 'success',
    Error: 'danger',
};

export enum DisplayState {
    show = 'show',
    hidden = 'hidden',
    remove = 'remove',
}

export enum Permission {
    read = 'read',
    write = 'write',
}


export enum NotificationType {
    error = 'error',
    warning = 'warning',
    info = 'info',
}
