import * as Livewire from "@/interfaces/AlpineWired";
import { AlpineData, LivewireData, LivewireMethods } from "@/interfaces/Components/SecondCounter";
import { createAlpineComponent } from '@/app/services/utils';

type Self = Livewire.AlpineComponentItself<AlpineData, LivewireData, LivewireMethods>;

export default (initialSeconds = 5) => createAlpineComponent<AlpineData, LivewireData, LivewireMethods>({
    seconds: initialSeconds,
    intervalId: null,

    init() {
        this.restartCounter(initialSeconds);
    },

    /**
     * Update the second display
     */
    updateTime(self: Self): void {
        self.seconds -= 1;
        if (0 === self.seconds) {
            self.removeTimer();
        }
    },

    /**
     * Restart the counter
     *
     * @param restartWithSeconds The initial seconds time
     */
    restartCounter(restartWithSeconds: number): void {
        this.removeTimer();
        this.seconds = restartWithSeconds;
        this.intervalId = setInterval(() => { this.updateTime(this); }, 1000);
    },

    /**
     * Remove the runned timer
     */
    removeTimer(): void {
        if (null !== this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    },
});
