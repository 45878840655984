<script lang="ts">
import { ComponentPublicInstance, defineComponent } from 'vue';
import * as Livewire from '@/interfaces/AlpineWired';
import { MissingLivewireHTMLElement, MissingHTMLElement } from '@/js/app/exceptions';
import { nearestLivewireComponent } from '@/js/app/services/utils';
import { VueData, LivewireData, LivewireMethods } from '@/js/interfaces/Components/vue/EstimateListing';


export default defineComponent({
    data(): VueData {
        return {
            livewire: null,
            buttonInDOM: false,
            isEnabled: false,
            classActive: ["bg-primary", "hover:bg-primary-hover", "focus:ring-red-400"],
            classInactive: ["bg-gray-500", "hover:bg-gray-600", "focus:ring-gray-400"],
        };
    },
    computed: {
        button(): HTMLElement {
            const button = document.getElementById('estimate-share-toggle-button');
            if (null === button) {
                throw new MissingHTMLElement("Missing button");
            }
            return button;
        },
    },
    mounted(): void {
        // Get livewire instance ("computed" cause error)
        this.livewire = this.getLivewire();

        this.buttonExistsInDOM = this.livewire.$wire.get('canTogglePmShareFeature');
        // The following operations should only be applied if the button is supposed to exists
        if (!this.buttonExistsInDOM) {
            return;
        }

        // Initialize data from server state
        this.isEnabled = this.livewire.$wire.$get('enablePmShareFeature');

        // Button is already a primary button by default
        if (!this.isEnabled) {
            const button = this.button;
            this.classInactive.forEach(cssClass => button.classList.toggle(cssClass));
        }

        this.button.addEventListener('click', this.toggleButton);
    },
    unmounted() {
        if (this.buttonExistsInDOM) {
            this.button.removeEventListener('click', this.toggleButton);
        }
    },
    methods: {
        /**
         * Retrieve the nearest livewire component instance.
         */
        getLivewire() {
            const component = nearestLivewireComponent((this.$root as ComponentPublicInstance).$el);

            if (null === component) {
                throw new MissingLivewireHTMLElement('We are not able to find a livewire component');
            }

            return component.__livewire as Livewire.Component<LivewireData, LivewireMethods>;
        },
        toggleButton() {
            // Update livewire
            this.livewire.$wire.$set('enablePmShareFeature', this.isEnabled);

            // Update the component data and render
            this.isEnabled = !this.isEnabled;

            const button = this.button;
            this.classActive.forEach(cssClass => button.classList.toggle(cssClass));
            this.classInactive.forEach(cssClass => button.classList.toggle(cssClass));
        },
    },
});
</script>