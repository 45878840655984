<script lang="ts">
import { ComponentPublicInstance, defineComponent } from 'vue';
import { Edge, Node, VueFlow } from "@vue-flow/core";
import * as Livewire from '@/interfaces/AlpineWired';
import is from '@/js/app/services/is';
import { InvalidArgumentException, MissingLivewireHTMLElement } from '@/js/app/exceptions';
import { handleError, nearestLivewireComponent } from '@/js/app/services/utils';
import { VueData, LivewireData, LivewireMethods } from '@/js/interfaces/Components/vue/EstimateProcessesWorkflow';


export default defineComponent({
    components: {
        VueFlow,
    },
    data(): VueData {
        return {
            workflowData: [
                {
                    id: "dummy",
                    label: "Loading...",
                    position: { x: 500, y: 120 },

                },
            ],
        };
    },
    computed: {
        /**
         * Retrieve the nearest livewire component instance.
         */
        livewire() {
            const component = nearestLivewireComponent((this.$root as ComponentPublicInstance).$el);

            if (null === component) {
                throw new MissingLivewireHTMLElement('We are not able to find a livewire component');
            }

            return component.__livewire as Livewire.Component<LivewireData, LivewireMethods>;
        },
    },
    mounted(): void {
        this.livewire
            .$wire
            .$call("getWorkflowData")
            .then((data: unknown) => {
                // Check retrieved type
                if (!is.array<Node | Edge>(data)) {
                    throw new InvalidArgumentException('The ajax content should return an array');
                }
                data.forEach((item: unknown) => {
                    if (!is.object(item)) {
                        throw new InvalidArgumentException('The ajax content should return a record of WorkflowCollection interface');
                    }
                });
                this.workflowData = data;
            })
            .catch((err: Error) => {
                handleError(err);
            });
    },
});
</script>

<template>
    <div id="flow-parent">
        <VueFlow v-model="workflowData" class="bg-gray-50" />
    </div>
</template>

<style>
/* import the necessary styles for Vue Flow to work */
@import "@vue-flow/core/dist/style.css";

/* import the default theme, this is optional but generally recommended */
@import "@vue-flow/core/dist/theme-default.css";

#flow-parent {
    width: 100%;
    height: 300px;
}

/* .vue-flow__node-custom {
    background: purple;
    color: white;
    border: 1px solid purple;
    border-radius: 4px;
    box-shadow: 0 0 0 1px purple;
    padding: 8px;
} */
</style>
