import fr from '@/lang/fr.json';
import { copyString } from '@/app/services/utils';

/**
 * Translate messages into another langage.
 */
export default class Translator {
    /** List of supported langages */
    availableLanguages = ['en', 'fr'];

    /** List of translations */
    languages: Record<string, Record<string, string>> = {};
    /** The current language used */
    locale: string = 'fr';
    /** The fallback language if no translation entry was found */
    fallback: string = 'en';

    constructor() {
        this.fillTranslationsFromFilesystem();
        this.determineLocale();
    }

    fillTranslationsFromFilesystem() {
        this.languages = { fr };
    }

    determineLocale() {
        if (this.availableLanguages.includes(navigator.language)) {
            this.locale = navigator.language;
        }
    }

    /**
     * Translate a message.
     *
     * @param message The message to translate
     * @param params Parts of message to replace.
     */
    translate(message: string, params: Record<string, string> = {}) {
        if (
            this.locale in this.languages
            && message in this.languages[this.locale]
        ) {
            let entry = copyString(this.languages[this.locale][message]);

            // Perform replacements
            Object.entries(params).forEach(([key, value]) => {
                // Do not care if the developper used ':' as first character
                if (':' === key[0]) {
                    key = key.slice(1);
                }
                entry = entry.replace(':' + key, value);
            });

            return entry;
        }

        // Yes, we can merge the two replacement action you think. But code is more optimized in performance like this.
        // Think about execution time and not only about code cleaning.

        // Replace content from original langage (english)
        Object.entries(params).forEach(([key, value]) => {
            // Do not care if the developper used ':' as first character
            if (':' === key[0]) {
                key = key.slice(1);
            }
            message = message.replace(':' + key, value);
        });

        return message;
    }
}
